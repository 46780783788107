import "./App.css";
import React from "react";
import { write, useSnap } from "./store";
import toast, { Toaster, ToastBar } from "react-hot-toast";
import { Button , Tooltip  } from "flowbite-react";
import { ref } from "valtio";
// import Modal from "./components/Modal";
import Webcam from "react-webcam";
import NewModal from "./components/newModal";
import { locale } from "./components/locale";
import Permissions from "./components/permissions";

let micTestInterval;

function Test() {
  const webcamRef = React.useRef();
  const read = useSnap();
  const audioCanvasRef = React.useRef();
  const [mics, setMics] = React.useState([]);
  const [webcams, setWebcams] = React.useState([]);
  const [openModal, setOpenModal] = React.useState('');

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera; //userAgentData?

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }
    if (/android/i.test(userAgent)) {
      return "Android";
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }
    return "PC";
  }

  React.useEffect(() => {
    write.OS = getMobileOperatingSystem();

    const timeoutID = window.setTimeout(() => {
      write.page = 'DC'
      read.ws.close() //? if the user doesn't enter the exam, it will disconnect them from our server within 60 mins.
      clearInterval(micTestInterval)
    }, 3600000);
    return () => window.clearTimeout(timeoutID);
  }, []);

  // console.log(read.OS, "🪠🪠🪠");

  // React.useEffect(() => {
  //     if(read.OS === 'PC'){
  //         navigator.mediaDevices.getDisplayMedia({ video: true, audio: false }).then((sStream) => { //! should be activated later
  //          const sharedScreenName = sStream.getVideoTracks()[0].getSettings().displaySurface
  //                  write.sStream = ref(sStream)
  //    })
  //     }
  // }, [read.OS]);



  // console.log(read.activeMic, read.activeWebcam)

  // React.useEffect(() => {
  //   let visibilityHidden, visibilityChange;
  //   // Opera 12.10 and Firefox 18 and later support
  //   if (typeof document.hidden !== "undefined") {
  //     visibilityHidden = "hidden";
  //     visibilityChange = "visibilitychange";
  //   } else if (typeof document.mozHidden !== "undefined") {
  //     visibilityHidden = "mozHidden";
  //     visibilityChange = "mozvisibilitychange";
  //   } else if (typeof document.msHidden !== "undefined") {
  //     visibilityHidden = "msHidden";
  //     visibilityChange = "msvisibilitychange";
  //   } else if (typeof document.webkitHidden !== "undefined") {
  //     visibilityHidden = "webkitHidden";
  //     visibilityChange = "webkitvisibilitychange";
  //   }

  //   function fn() {
  //     if (document[visibilityHidden]) {
  //       write.tabControl++
  //     }
  //   }

  //   document.addEventListener(visibilityChange, fn)
  //   return () => window.removeEventListener(visibilityChange, fn);
  // }, []);


  // console.log('🙈🙈🙈🙈', read.tabControl)



  const handleWMedia = React.useCallback(function handleWMedia(stream) {
    write.stream = ref(stream);
  }, []);



  React.useEffect(() => {
    async function runIt() {
      console.log('are we here?')
      if (read.OS === "PC") {
        const micPerm = await navigator.permissions.query({
          name: "microphone",
        });
        const camPerm = await navigator.permissions.query({ name: "camera" });
        if (micPerm.state === "granted" && camPerm.state === "granted") {
          // OK - Access has been granted to the microphone
          toast.success("🎤 and 📷 ok!", { duration: 8000 });
          write.micAndCamState = true;
        } else {
          toast.error("Allow webcam and microphone usage", {
            duration: Infinity,
            id: "allow",
          });
          write.micAndCamState = false;
        }

        camPerm.onchange = () => {
          // console.log('change works 🤣🤣🤣🤣🤣🤣🤣')
          write.page = 'CAMMICOFF'
          // window.location.reload(); // is it needed?
        };
      }

      // if (read.OS === "iOS" || read.OS === "Android") { //! checks if the camera turns off
      //   // const vTrack = read.stream.getVideoTracks()[0];
      //   setInterval(async () => {
      //     const camPerm = await navigator.permissions.query({ name: "camera" });
      //     // console.log(vTrack.readyState, 'zzzzzzzzzzzzz', camPerm.state)
      //     camPerm.state !== 'granted' && (write.page = 'CAMMICOFF')
      //   }, 3000);
      // }





      const audioTracks = read.stream.getAudioTracks();
      audioTracks.length === 0 &&
        toast.error("No audio from microphone has been captured", {
          duration: 8888,
        });
      const track = audioTracks[0];
      if (track) {
        if (track.muted) {
          toast.error("Microphone is muted", {
            duration: Infinity,
            id: "muted",
          })
          write.micAndCamState = false
        }
        if (!track.enabled) {
          toast.error("Microphone is disabled", {
            duration: Infinity,
            id: "disabled",
          })
          write.micAndCamState = false
        }
        if (track.readyState === "ended") {
          toast.error("Possibly a disconnection of the device", {
            duration: Infinity,
          })
          write.micAndCamState = false
        }

        track.addEventListener("unmute", () => {
          // window.location.reload()
          toast.dismiss("muted");
          write.micAndCamState = true;
        });
        track.addEventListener("mute", () => {
          toast.error("Microphone is muted", {
            duration: Infinity,
            id: "muted",
          });
          write.micAndCamState = false;
        });

        const audioContext = new AudioContext();
        const analyzer = audioContext.createAnalyser();
        analyzer.fftSize = 512;
        analyzer.smoothingTimeConstant = 0.1;
        const sourceNode = audioContext.createMediaStreamSource(read.stream);
        sourceNode.connect(analyzer);
        const canvasCtx = audioCanvasRef.current.getContext("2d");
        micTestInterval = setInterval(() => {
          // Compute the max volume level (-Infinity...0)
          const fftBins = new Float32Array(analyzer.frequencyBinCount); // Number of values manipulated for each sample
          analyzer.getFloatFrequencyData(fftBins);
          // audioPeakDB varies from -Infinity up to 0
          // const audioPeakDB = Math.max(...fftBins);

          // Compute a wave (0...)
          const bufferLength = analyzer.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          const barWidth =
            `${audioCanvasRef.current?.width ? audioCanvasRef.current.width : 100
            }` /
            bufferLength +
            7;
          analyzer.getByteFrequencyData(dataArray);

          const sum = dataArray.reduce((p, c) => p + c, 0);
          // audioMeter varies from 0 to 10
          const audioMeter = (Math.sqrt(sum / dataArray.length) * 10) | 0;

          let x = 0;
          canvasCtx.clearRect(
            0,
            0,
            audioCanvasRef.current?.width,
            audioCanvasRef.current?.height
          );
          for (let i = 0; i < bufferLength; i++) {
            let barHeight = dataArray[i];
            canvasCtx.fillStyle =
              "#" + (((1 << 24) * Math.random()) | 0).toString(16);
            canvasCtx.fillRect(
              x,
              audioCanvasRef.current?.height - barHeight,
              barWidth,
              barHeight
            );
            x += barWidth;
          }

          // console.log(`audioMeter` , audioPeakDB , audioMeter)
          if (audioMeter !== 0 && read.micAndCamState !== true) {
            write.micAndCamState = true;
          }
          // if (audioMeter < 3) {
          //   write.micAndCamState = false
          // }
        }, 111);
      }
    }

    read.stream.active && runIt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [read.activeMic, read.activeWebcam, read.OS, read.stream]);

  const handleDevices = React.useCallback((mediaDevices) => {
    setWebcams(mediaDevices.filter(({ kind }) => kind === "videoinput"));
    setMics(mediaDevices.filter(({ kind }) => kind === "audioinput"));
    // console.log(`mediaDevices` , mediaDevices)
  }, []);

  React.useEffect(() => {
    !read.activeWebcam &&
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [read.activeWebcam, handleDevices, read.activeMic, read.micAndCamState]);

  function handleSelect(e) {
    if (e.target.getAttribute("about") === "cam") {
      clearInterval(micTestInterval);
      write.activeWebcam = e.target.value;
    }
    if (e.target.getAttribute("about") === "mic") {
      clearInterval(micTestInterval);
      write.activeMic = e.target.value;
    }
  }

  function goToRec() {
    clearInterval(micTestInterval);
    write.page = "QUESTIONS";
  }

  //  React.useEffect(() => { //! SHOULD BE REMOVED LATER ON
  //   setTimeout(() => {
  //     goToRec()
  //   }, 1111);
  //  }, []);




  function handleModal(what) {
    write.instruction[what] = false
    setOpenModal(what)
  }


  // console.log(read.micAndCamState, read.stream.active, '🍧🍧🍧')


  return (
    <>
      <div className={`flex flex-col items-center  md:max-w-full ${read.micAndCamState ? '' : 'hidden'}`}>

        <pre>Welcome {read.user.name}</pre>
        <Webcam

          ref={webcamRef}
          className={`w-2/6 md:max-w-sm`}
          audio={true}
          muted={true}
          onUserMedia={(e) => handleWMedia(e)}
          audioConstraints={{
            deviceId: read.activeMic ? read.activeMic : undefined,
          }}
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: "user",
            deviceId: read.activeWebcam ? read.activeWebcam : undefined,
          }}
        />

        {read.OS === "PC" && (
          <>
            <div className="flex flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-auto h-6 m-1"
              >
                <path
                  strokeLinecap="round"
                  d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z"
                />
              </svg>{" "}
              <h6>SELECT WEBCAM</h6>
            </div>
            <select
              className="form-select form-select-lg mb-3
      appearance-none
      block
      mt-2
      w-4/5
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label=".form-select-lg example"
              onChange={handleSelect}
              about="cam"
            >
              {webcams.map((el) => (
                <option value={el.deviceId} key={el.deviceId}>
                  {el.label}
                </option>
              ))}
            </select>

            <div className="flex flex-row items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                />
              </svg>
              <h6>SELECT MICROPHONE</h6>
            </div>
            <select
              className="form-select form-select-lg mb-3
      appearance-none
      block
      mt-2
      w-4/5
      px-4
      py-2
      text-xl
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              aria-label=".form-select-lg example"
              onChange={handleSelect}
              about="mic"
            >
              {mics.map((el) => (
                <option value={el.deviceId} key={el.deviceId}>
                  {el.label}
                </option>
              ))}
            </select>
          </>
        )}

        <canvas
          ref={audioCanvasRef}
          className="w-4/5 mb-5 h-14 border-t-black border-2 items-center"
        />




        <div className="flex items-center">
          <input
            id="link-checkbox"
            onClick={() => handleModal('tos1')}
            type="checkbox"
            onChange={() => null}
            checked={read.instruction.tos1}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            <p>
              {locale[read.user.language].button.agree} {" "}
              <button
                onClick={() => handleModal('tos1')}
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                {locale[read.user.language].button.terms}
              </button>
            </p>{" "}
          </label>
        </div>
        <div className="flex items-center">
          <input
            id="link-checkbox"
            onClick={() => handleModal('tos2')}
            type="checkbox"
            onChange={() => null}
            checked={read.instruction.tos2}
            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            <p>
              {locale[read.user.language].button.consent}{" "}
              <button
                onClick={() => handleModal('tos2')}
                className="text-blue-600 dark:text-blue-500 hover:underline"
              >
                {locale[read.user.language].button.usage}
              </button>
            </p>{" "}
          </label>
        </div>

        {openModal && <NewModal type={openModal} />}


        <NewModal type='welcome' title="General Instruction" />


        <Tooltip content={ read.micAndCamState ? '👍' : 'Lütfen kamera ve mikrofon iznini videoda gösterildiği gibi açınız. Lütfen kameranızın bağlı olduğundan emin olun. Çalışan bir kamera olmadan sınava giremezsiniz. Please make sure your camera access is granted and your camera and microphone is connected to your device.'} placement="bottom">
        <Button
          type="button"
          disabled={
            read.micAndCamState &&
              read.instruction.tos1 &&
              read.instruction.tos2 &&
              read.instruction.welcome
              ? false
              : true
          }
          onClick={goToRec}
          color="success"
        >
          {locale[read.user.language].button.start}
        </Button>
        </Tooltip>
        


      </div>
      {
        !read.micAndCamState && <> <p className=" text-center text-sm ">Lütfen kamera ve mikrofon iznini videoda gösterildiği gibi açınız. Lütfen kameranızın bağlı olduğundan emin olun. Çalışan bir kamera olmadan sınava giremezsiniz. Please make sure your camera access is granted and your camera and microphone is connected to your device.</p> <img className=" h-3/4 w-3/4 ml-auto mr-auto md:w-full md:h-fit" alt="instruction" src='https://v2.globalassessment.com.tr/api/assets/camoffinstruction.gif' /> </>
      }
    </>
  );
}

export default Test;
